import { IClaimAdjudicationConfigsApiResponse } from 'types/claim-adjudication-config';
import api from 'utils/api';
import { slotConfig } from 'utils/api';
import { AxiosResponse } from 'axios';

export const ClaimAdjudicationConfigsService = async (): Promise<IClaimAdjudicationConfigsApiResponse | unknown> => {
    try {
        const response = await api.get<
            IClaimAdjudicationConfigsApiResponse,
            AxiosResponse<IClaimAdjudicationConfigsApiResponse>
        >('claimadjudicationconfigs', slotConfig);
        return response.data;
    } catch (error) {
        console.error('::Get ClaimAdjudicationConfigs.getAdjudicationConfigs API Error::', error);
        return;
    }
};
