import * as React from 'react';
import { ICertificateStateTypeState, ICertificateStateType } from './CertificateStateTypes';
import { IChildrenProps } from 'types/children';
import { IAlertType } from 'context/Alert';
import { LocalStorageKeys } from 'enums/LocalStorageKeys';

export const CertificateStateContext = React.createContext<ICertificateStateTypeState | null>(null);
const CertificateStateProvider: React.FC<IChildrenProps> = ({ children }) => {
    const [stateCertificateState, setStateCertificateState] = React.useState<ICertificateStateType>({
        certificateView: false
    });
    const updateContextCertificateState = (data: ICertificateStateType): void => {
        setStateCertificateState(data);
    };
    const stateObject: ICertificateStateTypeState = {
        contextStateCertificateState: stateCertificateState,
        updateContextStateCertificateState: updateContextCertificateState
    };
    return <CertificateStateContext.Provider value={stateObject}>{children}</CertificateStateContext.Provider>;
};
export default CertificateStateProvider;
