import styled from 'styled-components';
import { $brightBlue, $darkGray, $deepBlue, $deepBlue20, $digitalBlack } from 'styles/global-styles';

interface ILabelWrapper {
    labelHeight: boolean;
}
interface ILabel {
    paddingBottom: boolean;
}
interface ILabelIcon {
    transform: boolean;
}
interface IContent {
    visibleState: boolean;
}
export const SCLabelContentWrapper = styled.div`
    border-top: ${$deepBlue20} solid 1px;
    border-bottom: ${$deepBlue20} solid 1px;
    padding-bottom: 40px;
    &:not(:last-of-type) {
        border-bottom: transparent;
        padding-bottom: 0;
    }
`;
export const SCLabelWrapper = styled.div<ILabelWrapper>`
    cursor: pointer;
    overflow: hidden;
    height: fit-content;
    transition: height ease-out 0.75s;
`;
export const SCLabel = styled.div<ILabel>`
    border-radius: 5px;
    padding-top: 36px;
    padding-bottom: ${(props): number | string => (props.paddingBottom ? '18px' : '0px')};
    transition: all 0.25s ease-out;
    @media (max-width: 1024px) {
        gap: 16px;
        max-width: 100%;
    }
`;
export const SCLabelContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 0 0;
    color: ${$deepBlue};
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 21px;
    }
`;
export const SCLabelIcon = styled.div<ILabelIcon>`
    fill: ${$brightBlue};
    transform: ${(props): string => (props.transform ? `rotate(0deg)` : `rotate(45deg)`)};
    transition: transform 0.25s ease-out;
    margin-left: 28px;
`;
export const SCContent = styled.div<IContent>`
    height: ${(props): string => (props.visibleState ? 'fit-content' : '0')};
    opacity: ${(props): number => (props.visibleState ? 1 : 0.0)};
    overflow: hidden;
    padding-top: ${(props): string => (props.visibleState ? '18px' : '0px')};
    transition: all 0.4s ease-out;
    a {
        color: ${$brightBlue};
        cursor: pointer;
    }
    ul {
        list-style-position: outside;
        margin-left: 18px;
    }
    ul > li {
        padding-left: 4px;
        margin-left: 18px;
        list-style-type: disc;
    }
    ul > ul > li {
        margin-left: 18px;
        list-style-type: circle;
    }
    ol {
        list-style-position: outside;
        margin-left: 18px;
    }
    ol > li {
        padding-left: 4px;
        margin-left: 18px;
        list-style-type: lower-alpha;
    }
    @media (max-width: 1024px) {
        font-size: 14px;
    }
`;
export const SCGroupLabel = styled.h3`
    font-weight: 700;
    font-style: normal;
    font-size: 48px;
    color: ${$darkGray};
    text-transform: capitalize;
    line-height: 25px;
    letter-spacing: 0.15rem;
    padding: 36px 0 16px 0;
    @media (max-width: 1440px) {
        font-size: 24px;
        line-height: 25px;
        padding: 16px 0 16px 0;
    }
`;
export const SCTopLine = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 8px 0;
    color: ${$deepBlue};
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
`;
export const SCExpandContractGroupButton = styled.div`
    padding: 8px 16px 8px 0;
    color: ${$digitalBlack};
    font-weight: 700;
    font-size: 21px;
    line-height: 21px;
    cursor: pointer;
`;
